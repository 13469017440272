<template>
  <div>
    <PageHeader :title="pageTitle" :items="breadcrumb" />

    <b-button variant="success" class="mt-2" @click="showModal = true">
      Cadastrar Feriado
    </b-button>

    <b-card class="mt-3">
      <h4 class="titulo">Feriados</h4>
      <b-table :items="feriados" :fields="camposTabela" striped hover>
        <template v-slot:cell(remover)="row">
          <b-button variant="danger" size="sm" @click="removeFeriado(row.item)">
            Remover
          </b-button>
        </template>
        <template v-slot:cell(data)="row">
          {{ formatarFeriado(row.value) }}
        </template>
        <template v-slot:cell(tipo_feriado)="row">
          {{ obterNomeTipo(row.value) }}
        </template>
      </b-table>
    </b-card>

    <!-- Feriado Modal -->
    <b-modal v-model="showModal" title="Cadastrar Feriado" hide-footer>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="data">Data</label>
            <b-form-datepicker
              v-model="newFeriado.data"
              class="form-control"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              locale="pt-br"
            />
          </div>

          <div class="form-group">
            <label for="descricao">Descrição</label>
            <input
              type="text"
              class="form-control"
              v-model="newFeriado.descricao"
            />
          </div>

          <div class="form-group">
            <label for="tipo_feriado">Tipo</label>
            <b-form-select
              v-model="newFeriado.tipo_feriado"
              :options="tipos"
            ></b-form-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="municipio">Município</label>
            <input
              type="text"
              class="form-control"
              v-model="newFeriado.municipio"
            />
          </div>

          <div class="form-group">
            <label for="UF">UF</label>
            <b-form-select
              v-model="newFeriado.uf"
              :options="estadosOptions"
            ></b-form-select>
          </div>
        </div>
      </div>

      <b-button variant="primary" @click="addFeriado">Adicionar</b-button>
      <b-button variant="secondary" class="ml-2" @click="showModal = false">
        Cancelar
      </b-button>
    </b-modal>

    <b-button variant="primary" class="mt-3" @click="submitFeriados">
      Salvar Lista
    </b-button>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import { salvar, formatarData } from './feriado.service';
import estados from '@/utils/estados';

export default {
  components: {
    PageHeader
  },
  data() {
    return {
      pageTitle: 'Cadastrar Feriado',
      breadcrumb: [
        { text: 'Dias Úteis', href: '/' },
        { text: 'Cadastrar', active: true }
      ],
      showModal: false,
      newFeriado: {
        data: '',
        descricao: '',
        tipo_feriado: '',
        municipio: '',
        uf: ''
      },
      feriados: [],
      camposTabela: [
        { key: 'id', label: 'ID' },
        { key: 'data', label: 'Data' },
        { key: 'descricao', label: 'Descrição' },
        { key: 'tipo_feriado', label: 'Tipo' },
        { key: 'municipio', label: 'Município' },
        { key: 'uf', label: 'UF' },
        { key: 'remover', label: 'Ações' }
      ],
      tipos: [
        { text: 'Nacional', value: 'N' },
        { text: 'Estadual', value: 'E' },
        { text: 'Municipal', value: 'M' }
      ],
      estadosOptions: estados()
    };
  },
  methods: {
    addFeriado() {
      // Calculate the next available ID based on existing IDs
      const existingIds = this.feriados.map((item) => item.id);
      const nextId = existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;

      // Add the newFeriado object with the calculated ID to the feriados array
      this.feriados.push({
        id: nextId,
        ...this.newFeriado
      });
      this.newFeriado = {
        data: '',
        descricao: '',
        tipo_feriado: '',
        municipio: '',
        uf: ''
      };

      // Close the modal
      this.showModal = false;
    },
    removeFeriado(feriado) {
      const index = this.feriados.indexOf(feriado);
      if (index !== -1) {
        this.feriados.splice(index, 1);
      }
    },
    submitFeriados() {
      salvar(this.feriados)
        .then(() => {
          this.feriados = [];
        })
        .catch((erro) => {
          console.error('Erro ao salvar:', erro);
        });
    },
    formatarFeriado(data) {
      return formatarData(data);
    },
    obterNomeTipo(tipo) {
      switch (tipo) {
        case 'N':
          return 'Nacional';
        case 'E':
          return 'Estadual';
        case 'M':
          return 'Municipal';
        default:
          return '';
      }
    }
  }
};
</script>
