import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listAll = async () => {
  var axios = require('axios');

  //const { token } = getAuth();
  const auth = getAuth();
  var config = {
    method: 'get',
    url: `${CONFIG.API_URL}/feriado/listAll`,
    headers: {
      Bearer: auth.token
    }
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
};

export const salvar = async (feriados) => {
  //return mockResponse;
  //console.log(feriados);

  var axios = require('axios');
  var data = { feriados: feriados };

  //console.log(data);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/feriado/saveList`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      Vue.$toast.success('Dados cadastrados com sucesso.', response.content);
      return response.message;
    })
    .catch(function (error) {
      Vue.$toast.error(
        'Sua tentativa de cadastrar os dados dos feriados falhou.',
        error
      );
    });
};

export const update = async (feriado) => {
  //return mockResponse;

  var axios = require('axios');
  var data = { feriado };

  //console.log(data);

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/feriado/atualizar`,
    headers: {
      Bearer: auth.token
    },
    data: data.feriado
  };

  return axios(config)
    .then(function (response) {
      //console.log(response);
      Vue.$toast.success('Dados atualizados com sucesso.', response.content);
      return response.message;
    })
    .catch(function (error) {
      Vue.$toast.error(
        'Sua tentativa de atualizar os dados do feriado falhou.'
      );
      //console.log(error);
    });
};

export const remove = async (id) => {
  //return mockResponse;

  var axios = require('axios');
  var data = { id: id };

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL}/feriado/remover`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      //console.log(response);
      Vue.$toast.success('Dados Cadastrados.', response.content);
      return response.message;
    })
    .catch(function (error) {
      Vue.$toast.error('Sua tentativa de remover a empresa falhou.');
      //console.log(error);
    });
};
export const formatarData = (data) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(data));
};
